import { NextPage } from 'next';
import Link from 'next/link';
import React from 'react';
import styled from 'styled-components';

import { useAuth0 } from '../libs/auth0/provider';
import getDebugPageProps, {
  DebugPageProps,
} from '../libs/ssr/getDebugPageProps';

const StyledAnker = styled.a`
  font-size: 14px;
  color: white;
  text-decoration: underline;
  cursor: pointer;
`;

type IndexPageProps = DebugPageProps;

const IndexPage: NextPage<IndexPageProps> = ({ museumPage }) => {
  if (!museumPage) {
    return null;
  }

  const { user, login, logout } = useAuth0();
  return (
    <ul>
      <li>
        <Link href={museumPage}>
          <StyledAnker>museum</StyledAnker>
        </Link>
      </li>
      <li>
        <Link href="/__test__/controller">
          <StyledAnker>controller</StyledAnker>
        </Link>
      </li>
      <li>
        <Link href="/__test__/three">
          <StyledAnker>three</StyledAnker>
        </Link>
      </li>
      <li>
        <Link href="/__test__/shopify">
          <StyledAnker>shopify cart test</StyledAnker>
        </Link>
      </li>
      <li>
        <Link href="/__test__/howler">
          <StyledAnker>audio play test</StyledAnker>
        </Link>
      </li>
      <li onClick={() => (user ? logout() : login())}>
        <StyledAnker>
          {user ? `logout (user: ${user.email})` : 'login'}
        </StyledAnker>
      </li>
    </ul>
  );
};

const getServerSideProps = getDebugPageProps;
export { getServerSideProps };
export default IndexPage;
